window.getLangJa = function() {
  return {
    meta_description: 'Ezefulは簡単で便利な無料のツール集です。',
    detailed_description: '',
    validation: {
      audio: ':attributeには、音声を指定してください。',
      audio_or_video: ':attributeには、音声または動画を指定してください。',
      image: ':attributeには、画像を指定してください。',
      video: ':attributeには、動画を指定してください。',
      unknown: '処理に失敗しました。入力内容をご確認ください。',
      no_input: 'ファイルが指定されていません。',
    },
    label: {
      input: '入力',
      output: '出力',
      target: '対象',
      result: '結果',
      source_file: '元ファイル',
      source_format: '変換前形式',
      source_charset: '変換前文字コード',
      source_encoding: '変換前エンコード',
      destination_format: '変換後形式',
      destination_charset: '変換後文字コード',
      destination_encoding: '変換後エンコード',
      execute: '実行',
      download: 'ダウンロード',
      to_json: 'JSONに変換',
      to_yaml: 'YAMLに変換',
      to_toml: 'TOMLに変換',
      to_xml: 'XMLに変換',
      to_csv: 'CSVに変換',
      delimiter: '区切り文字',
      indent: 'インデント',
      space: 'スペース',
      transform: '変換',
      convert: '変換',
      beautify: '整形',
      minify: '圧縮',
      generate: '生成',
      copy: 'コピー',
      open: '開く',
      close: '閉じる',
      auto: '自動',
      number: '件数',
      length: '長さ',
      regex: '正規表現',
      sample: 'サンプル',
      quality: '品質',
      preview: 'プレビュー',
      add_effect: 'エフェクト追加',
      bitrate: 'ビットレート',
      previous: '戻る',
      next: '進む',
      encoder: 'エンコーダー',
      size: 'サイズ',
      bit_rate: 'ビットレート',
      sampling_rate: 'サンプリングレート',
      frame_rate: 'フレームレート',
      not_specified: '指定しない',
      not_change: '変更しない',
      auto_download: '自動ダウンロード',
      advanced_setting: '詳細設定',
    },
    hint: {
      select: '選択してください。'
    },
    placeholder: {
      json: 'こちらにJSON形式で入力をしてください。',
      yaml: 'こちらにYAML形式で入力をしてください。',
      toml: 'こちらにTOML形式で入力をしてください。',
      xml: 'こちらにXML形式で入力をしてください。',
      csv: 'こちらにCSV形式で入力をしてください。',
      regex: 'こちらに正規表現を入力してください。',
      text: 'こちらにテキストを入力してください。',
      string: 'こちらに文字列を入力してください。',
    },
    alert: {
      result: 'こちらに結果が表示されます。',
      copied: 'クリップボードにコピーされました。',
    },
    attribute: {
      json: 'JSON',
      yaml: 'YAML',
      toml: 'TOML',
      xml: 'XML',
      csv: 'CSV',
      audio: '音声',
      video: '動画',
      image: '画像'
    },
    class: {
      multimedia: {
        name: 'マルチメディア',
      },
      text: {
        name: 'テキスト',
      },
      others: {
        name: 'その他'
      },
      language: {
        name: '言語'
      },
    },
    application: {
      contact: {
        name: 'お問い合わせ'
      },
      privacy_policy: {
        name: 'プライバシーポリシー',
        meta_description: '本プライバシーポリシーは、Ezeful.（https://ezeful.app）（以下、「当サイト」とします。)の各種サービス（当サイトによる情報提供、各種お問合せの受付等）において、当サイトの訪問者（以下、「訪問者」とします。）の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。 このプライバシーポリシーに記載されている場合を除き、お客様の情報を使用したり、他人と共有したりすることはありません。',
        detailed_description: '本プライバシーポリシーは、Ezeful.（https://ezeful.app）（以下、「当サイト」とします。)の各種サービス（当サイトによる情報提供、各種お問合せの受付等）において、当サイトの訪問者（以下、「訪問者」とします。）の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。 このプライバシーポリシーに記載されている場合を除き、お客様の情報を使用したり、他人と共有したりすることはありません。',
        label: {
          about_analytics: {
            name:'アクセス解析ツールについて',
            description: '当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br><br>このGoogleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。<br><br>GoogleアナリティクスのCookieは、26ヶ月間保持されます。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br><br>Googleアナリティクスの利用規約に関して確認したい場合は、<a href="https://www.google.com/analytics/terms/jp.html" target="_blank">https://www.google.com/analytics/terms/jp.html</a> にアクセスしてください。<br>また、「ユーザーが Google パートナーのサイトやアプリを使用する際の Google によるデータ使用」に関して確認したい場合は、<a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank">https://policies.google.com/technologies/partner-sites?hl=ja</a> にアクセスしてください。'
          },
          about_advertisement: {
            name:'第三者配信の広告サービスについて',
            //description: '当サイトは、第三者配信の広告サービス「Google Adsense（グーグルアドセンス）」を利用しています。<br><br>Googleなどの第三者広告配信事業者は、訪問者の興味に応じた広告を表示するために、Cookie（当サイトの訪問者が当サイトや他のサイトにアクセスした際の情報など）を使用することがあります。<br><br>訪問者は、広告設定で訪問者に合わせた広告（以下、「パーソナライズド広告」とします。）を無効にすることが出来ます。<br>その設定をする場合は、<a href="https://www.google.com/settings/ads" target="_blank">https://www.google.com/settings/ads</a> にアクセスしてください。<br>また、<a href="https://www.aboutads.info" target="_blank">https://www.aboutads.info</a> にアクセスすれば、パーソナライズド広告に使われる第三者配信事業者の Cookie を無効にできます。<br><br>第三者配信による広告掲載を無効にしていない場合は、第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることがあります。<br><br>Googleによって広告の第三者配信が認められている広告配信事業者の詳細は、<a href="https://support.google.com/dfp_premium/answer/94149" target="_blank">https://support.google.com/dfp_premium/answer/94149</a> にアクセスしてください。'
            description: '当サイトは、第三者配信の広告サービスを利用しています。<br><br>第三者広告配信事業者は、訪問者の興味に応じた広告を表示するために、Cookie（当サイトの訪問者が当サイトや他のサイトにアクセスした際の情報など）を使用することがあります。<br><br>Cookieはブラウザ設定から削除・無効化することができます。<br>詳細はお使いのブラウザのヘルプ等をご確認ください。'
            
          },
          purpose_personal_information: {
            name:'個人情報の利用目的',
            description: '当サイトでは、メールでのお問い合わせの際に、氏名（ハンドル名）やメールアドレスなどの個人情報の送信をお願いする場合があります。<br><br>これらの個人情報は、質問への回答やメール等でのご連絡に使用するものであり、それ以外の目的で利用することはありません。'
          },
          disclosure_personal_information: {
            name:'第三者への個人情報の開示',
            description: '当サイトでは、個人情報を適切に管理し、以下の場合を除き、第三者に開示することはありません。<br><br>・本人の同意がある場合<br>・法律に協力するために開示が必要な場合'
          },
          correspond_personal_information: {
            name:'個人情報の開示、訂正、追加、削除、利用停止',
            description: '本人の個人情報の開示、訂正、追加、削除、利用停止の場合は、本人であることを確認し、迅速に対応いたします。'
          },
          children: {
            name:'子供について',
            description: '当サイトは、16歳未満の子供に関する情報を、親の許可と同意なしに、故意に収集し、処理することはありません。 当サイトは、16歳未満の、またはEU加盟国法によって異なるGDPRと同趣旨の最低年齢に達しない子供の個人情報を直接収集し、処理していたことを発見した場合は、できるだけ速やかに情報を消去する措置を講じます。 16歳未満の子供が当サイトに個人情報を提供したことにお気付きの場合は、本方針に記載した連絡先を利用して直ちに当サイトにご連絡ください。'
          },
          eu_and_eea_users_rights: {
            name:'EUおよびEEAのユーザーの権利',
            description: '欧州連合または欧州経済領域に居住している場合、お客様は自身の情報にアクセス、修正、ダウンロード、または消去する権利、ならびにお客様の情報の特定の処理を制限および反対する権利を有します。<br>これらの権利の一部は一般的に適用されますが特定の権利は特定の限られた状況でのみ適用されます。<br>これらの権利について以下に説明します。<br><br>あなたにはあなたの個人データにアクセスする権利があり、必要に応じて、それを修正、削除、または制限することができ、場合によりデータの移植性に対する権利があります。<br>また、ダイレクトマーケティングの目的でプロファイリングを実行する場合は、マーケティングコミュニケーションを送信したり、個人データを使用したりしないように依頼することもできます。<br>これらの電子メールで提供されるオプトアウト手順に従うことにより、電子メールニュースレターおよびその他のマーケティングコミュニケーションの受信をオプトアウトできます。<br>マーケティングコミュニケーションをオプトアウトしても、Transactionalメッセージは影響を受けません。'
          },
          california_privacy_rights: {
            name:'カリフォルニア州のユーザーの権利',
            description: '個人、家族、または家庭で使用する製品またはサービスを取得する際に個人情報を提供するカリフォルニア州の居住者は、年に1回、他の企業と直接共有した顧客情報に関する情報を要求し、取得する権利があります。<br>該当する場合、この情報には、顧客情報のカテゴリと、前年に顧客情報を共有した企業の名前と住所が含まれます。<br>この情報を入手するには、「カリフォルニア州のプライバシー情報のリクエスト」という件名でinfo@ezeful.appまでメールでお問い合わせください。<br>リクエストされた情報を記載した返信メールをお送りします。<br>すべての情報共有がカリフォルニアの「Shine the Light法」でカバーされているわけではありません。'
          },
          disclaimer: {
            name:'免責事項',
            description: '当サイトに掲載されている内容により生じたいかなる損害についても、当サイトは責任を負いかねますので、あらかじめご了承ください。'
          },
          changes_privacy_policy: {
            name:'プライバシーポリシーの変更について',
            description: '当サイトは、個人情報に関して適用される各国の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。<br>修正された最新のプライバシーポリシーは常に本ページにて開示されます。'
          },
          last_updated: {
            name: '最終更新日',
            description: '令和4年01月21日',
          }
        }
      },
      audio_converter: {
        name: '音声コンバーター',
        meta_description: '音声コンバーターはお使いのブラウザで様々な音声ファイルおよび動画ファイルを無料で変換出来ます。',
        detailed_description: '音声コンバーターはお使いのブラウザで様々な音声ファイル(mp3, wav, aac, ogg, m4a, m4r, flac, opus, aiff)および動画ファイル(mp4, avi, mpg, mov, flv, 3gp, webm, mkv)を無料で変換出来ます。',
        label: {
          audio_setting: 'オーディオ設定',
        }
      },
      video_converter: {
        name: '動画コンバーター',
        meta_description: '動画コンバーターはお使いのブラウザで様々な動画ファイルを無料で変換出来ます。',
        detailed_description: '動画コンバーターはお使いのブラウザで様々な動画ファイル(mp4, avi, mpg, mov, flv, 3gp, webm, mkv)を無料で変換出来ます。',
        label: {
          video_setting: 'ビデオ設定',
          audio_setting: 'オーディオ設定',
        }
      },
      image_converter: {
        name: '画像コンバーター',
        meta_description: '画像コンバーターはお使いのブラウザで様々な画像ファイルを無料で変換出来ます。',
        detailed_description: '画像コンバーターはお使いのブラウザで様々な画像ファイル(png, jpeg, gif, webp)を無料で変換出来ます。',
        label: {
          effect_default: 'デフォルトに戻す',
          effect_grayscale: 'グレースケール',
          effect_threshold: 'しきい値処理',
          effect_invert: 'ネガポジ反転',
          effect_gamma: 'ガンマ補正',
        }
      },
      regex_tester: {
        name: '正規表現チェッカー',
        meta_description: '正規表現チェッカーでは簡単に正規表現をテストすることが出来ます。',
        detailed_description: '正規表現チェッカーでは簡単に正規表現をテストすることが出来ます。',
        label: {
          reference: 'リファレンス (MDN Web Docs)',
          flag: 'フラグ',
          character_classes: '文字クラス',
          assertions: '言明',
          groups_and_ranges: 'グループと範囲',
          quantifiers: '数量詞',
          result_unique: '重複を削除して表示',
          result_duplicate_only: '重複のみを表示',
          result_duplicate_unique: '重複のみユニーク化して表示',
          result_all: '全てを表示',
          output: {
            match: 'マッチ',
            group: 'グループ'
          }
        },
        url: {
          flag: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions#advanced_searching_with_flags',
          character_classes: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types',
          assertions: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#types',
          groups_and_ranges: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#types',
          quantifiers: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions/Quantifiers#types',
        },
      },
      //encode_tools: {
      //  name: 'エンコードツール',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      yaml_tools: {
        name: 'YAMLツール',
        meta_description: 'YAMLツールはYAMLデータのフォーマット、圧縮、JSON/TOML/XML形式への変換が行えます。',
        detailed_description: 'YAMLツールはYAMLデータのフォーマット、圧縮、JSON/TOML/XML形式への変換が行えます。',
      },
      json_tools: {
        name: 'JSONツール',
        meta_description: 'JSONツールはJSONデータのフォーマット、圧縮、YAML/TOML/XML形式への変換が行えます。',
        detailed_description: 'JSONツールはJSONデータのフォーマット、圧縮、YAML/TOML/XML形式への変換が行えます。',
      },
      toml_tools: {
        name: 'TOMLツール',
        meta_description: 'TOMLツールはTOMLデータのフォーマット、圧縮、YAML/JSON/XML形式への変換が行えます。',
        detailed_description: 'TOMLツールはTOMLデータのフォーマット、圧縮、YAML/JSON/XML形式への変換が行えます。',
      },
      xml_tools: {
        name: 'XMLツール',
        meta_description: 'XMLツールはXMLデータのフォーマット、圧縮、YAML/JSON/TOML形式への変換が行えます。',
        detailed_description: 'XMLツールはXMLデータのフォーマット、圧縮、YAML/JSON/TOML形式への変換が行えます。',
      },
      //csv_tools: {
      //  name: 'CSVツール',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      uuid_generator: {
        name: 'UUID生成機',
        meta_description: 'UUID生成機はUUID/GUIDバージョン4に準じた一意のIDを一括生成出来ます。',
        detailed_description: 'UUID生成機はUUID/GUIDバージョン4に準じた一意のIDを一括生成出来ます。',
      },
    },
  }
}
