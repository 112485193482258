window.getLangZhCn = function() { //简体中文
  return {
    meta_description: 'Ezeful 是简单、有用且免费的工具。',
    detailed_description: '',
    validation: {
      audio: ':attribute 必须是音频。',
      audio_or_video: ':attribute 必须是音频或视频。',
      image: ':attribute 必须是图像。',
      video: ':attribute 必须是视频。',
      unknown: '处理失败。 请检查您的输入。',
      no_input: '未指定输入文件。',
    },
    label: {
      input: '输入',
      output: '输出',
      target: '目标',
      result: '结果',
      source_file: '源文件',
      source_format: '源格式',
      source_charset: '源字符集',
      source_encoding: '源编码',
      destination_format: '目标格式',
      destination_charset: '目标字符集',
      destination_encoding: '目的地编码',
      execute: '执行',
      download: '下载',
      to_json: '转换为 JSON',
      to_yaml: '转换为 YAML',
      to_toml: '转换为 TOML',
      to_csv: '转换为 CSV',
      to_xml: '转换为 XML',
      delimiter: '分隔符',
      indent: '缩进',
      space: '空间',
      transform: '转变',
      convert: '兑换',
      beautify: '美化',
      minify: '缩小',
      generate: '产生',
      copy: '复制',
      open: '打开',
      close: '关闭',
      auto: '自动的',
      number: '数字',
      length: '长度',
      regex: '正则表达式',
      sample: '样本',
      option: '选项',
      flag: '标志',
      quality: '质量',
      preview: '预览',
      add_effect: '添加效果',
      previous: '前页',
      next: '后页',
      encoder: '编码器',
      size: '大小',
      bit_rate: '比特率',
      sampling_rate: '采样率',
      frame_rate: '帧率',
      not_specified: '未标明',
      not_change: '没变化',
      auto_download: '自动下载',
      advanced_setting: '高级设置',
    },
    hint: {
      select: '请选择。'
    },
    placeholder: {
      json: '请在此处输入 JSON 格式。',
      yaml: '请在此处输入 YAML 格式。',
      toml: '请在此处输入 TOML 格式。',
      xml: '请在此处输入 XML 格式。',
      csv: '请在此处输入 CSV 格式。',
      regex: '请在此处输入正则表达式。',
      text: '请在此处输入文字。',
      string: '请在此处输入字符串。',
    },
    alert: {
      result: '结果显示在这里。',
      copied: '它被复制到剪贴板。',
    },
    attribute: {
      json: 'JSON',
      yaml: 'YAML',
      toml: 'TOML',
      xml: 'XML',
      csv: 'CSV',
      audio: '音频',
      video: '视频',
      image: '图像'
    },
    class: {
      multimedia: {
        name: '多媒体',
      },
      text: {
        name: '文字',
      },
      others: {
        name: '其他'
      },
      language: {
        name: '语'
      },
    },
    application: {
      contact: {
        name: '接触'
      },
      privacy_policy: {
        name: '隐私政策',
        meta_description: '本隐私政策描述了我们在您使用服务时收集、使用和披露您的信息的政策和程序，并告诉您您的隐私权以及法律如何保护您。 除本隐私政策中所述外，我们不会使用或与任何人共享您的信息。',
        detailed_description: '本隐私政策描述了我们在您使用服务时收集、使用和披露您的信息的政策和程序，并告诉您您的隐私权以及法律如何保护您。 除本隐私政策中所述外，我们不会使用或与任何人共享您的信息。',
        label: {
          about_analytics: {
            name:'关于访问分析工具',
            description: '本网站使用 Google Analytics，这是一种由 Google 提供的访问分析工具。<br><br>Google Analytics 使用 cookie 来收集流量数据。 此流量数据是匿名收集的，不会识别任何个人。 <br><br>Google Analytics cookie 会保留 26 个月。 此功能允许您通过禁用 cookie 来拒绝收集，因此请检查您的浏览器设置。<br><br>如果您想查看 Google Analytics 的使用条款，请访问 <a href="https://www.google.com/analytics/terms/us.html" target="_blank">https://www.google.com/analytics/terms/us.html</a>。<br>另外，如果你想 了解“当用户使用 Google 合作伙伴网站和应用程序时 Google 对数据的使用”，请转到 <a href="https://policies.google.com/technologies/partner-sites?hl=zh-CN" target=" _blank">https://policies.google.com/technologies/partner-sites?hl=zh-CN</a>。 给我吗。'
          },
          about_advertisement: {
            name:'关于第三方广告服务',
            //description: '本网站使用第三方广告服务“Google Adsense”。<br><br>谷歌等第三方广告分销商使用cookies（如本网站访问者访问本网站或其他网站时的信息）根据访问者的利益。<br><br>访问者可以在广告设置中禁用特定于访问者的广告（以下简称“个性化广告”）。<br>为此，请转到 <a href="https://www.google.com/settings/ads" target="_blank">https://www.google.com/settings/ads</a> 。<br>您还可以禁用用于通过访问 <a href="https://www.aboutads.info" target="_blank">https://www.aboutads.info</a> 进行个性化广告。<br><br>如果您没有禁用第三方广告展示位置、来自第三方分销商和广告网络的广告可能会出现在您的网站上。<br><br>有关 Google 允许向第三方投放广告的广告分销商的更多信息，请访问<a href="https://support.google.com/dfp_premium/answer/94149" target="_blank">https://support.google.com/dfp_premium/answer/94149</a> 。'
            description: '本站使用第三方广告服务。<br><br>第三方广告分销商使用cookies（如本站访问者访问本站或其他网站时的信息）根据访问者的兴趣展示广告。<br><br>您可以从浏览器设置中删除或禁用 cookie。请查看浏览器的帮助以获取详细信息。'
          },
          purpose_personal_information: {
            name:'个人信息的使用目的',
            description: '当我通过电子邮件查询时，本网站可能会要求提交姓名（句柄名称）和电子邮件地址等个人信息。<br><br>这些个人信息将用于回答问题并通过电子邮件与您联系 邮件等，并且此类个人信息不会用于超出此类目的的任何目的。'
          },
          disclosure_personal_information: {
            name:'向第三方披露个人信息',
            description: '在本网站，个人信息得到妥善管理，除以下情况外，不会向第三人透露。<br><br>1. 征得当事人同意时<br>2. 当需要披露以配合法律时'
          },
          correspond_personal_information: {
            name:'个人信息的公开、更正、追加、删除、停止使用',
            description: '对于当事人的个人数据的公开、更正、追加、删除、停止使用等情况，我们将确认是当事人，并及时对应。'
          },
          children: {
            name:'孩子们',
            description: '本网站服务不适用于 16 岁以下的儿童，我们不会故意收集 16 岁以下儿童的信息。<br><br>16 岁或以下的儿童未经许可不得提交任何个人信息 他们的父母或监护人。 使用本网站服务，即表示您已年满 18 周岁，或者您已年满 16 周岁并获得您的父母或监护人的许可使用该服务。'
          },
          eu_and_eea_users_rights: {
            name:'欧盟和欧洲经济区用户的权利',
            description: '如果您习惯性地位于欧盟或欧洲经济区，您有权访问、更正、下载或删除您的信息，以及限制和反对对您的信息进行某些处理的权利。 虽然其中一些权利普遍适用，但某些权利仅适用于某些有限的情况。 我们在下面描述了这些权利：<br><br>您有权访问您的个人数据，并在必要时对其进行修改、删除或限制。<br>在某些情况下，您可能有权携带您的个人数据 数据。<br>您也可以要求我们在为直接营销目的进行分析时不要发送营销通讯，也不要使用您的个人数据。<br>您可以按照以下步骤选择不接收电子邮件通讯和其他营销通讯 这些电子邮件中提供给您的选择退出说明。<br>如果您选择退出营销通信，交易帐户消息将不受影响。'
          },
          california_privacy_rights: {
            name:'加州用户权利',
            description: '在获取产品或服务以供个人、家庭或家庭使用时提供个人信息的加州居民有权要求并从我们这里获取，每日历年一次，关于我们与其他企业共享的客户信息的信息（如果有的话），用于他们自己的直接 营销用途。<br>如果适用，此信息将包括客户信息的类别以及我们在前一个日历年与之共享客户信息的那些企业的名称和地址。<br>要获取此信息，请给我们发送电子邮件 info@ezeful.app，主题为“请求加州隐私信息”； 我们将向您发送一封包含所请求信息的回复电子邮件。<br>并非所有信息共享都包含在加利福尼亚“闪耀之光”要求中，我们的回复中仅包含有关共享共享的信息。'
          },
          disclaimer: {
            name:'免责声明',
            description: '请理解，对于本网站上发布的内容造成的任何损害，我们概不负责。'
          },
          changes_privacy_policy: {
            name:'隐私政策的变更',
            description: '本网站遵守适用于个人信息的美国和日本法律，并努力酌情审查和改进本政策的内容。<br><br>更新后的隐私政策将始终在此页面上披露。'
          },
          last_updated: {
            name: '最近更新时间',
            description: '二零二二年 一月 二十一号',
          }
        }
      },
      audio_converter: {
        name: '音频转换器',
        meta_description: '音频转换器可以在您的浏览器中免费转换各种音频和视频文件。',
        detailed_description: '音频转换器可以在您的浏览器中免费转换各种音频（mp3、wav、aac、ogg、m4a、m4r、flac、opus、aiff）和视频（mp4、avi、mpg、mov、flv、3gp、webm、mkv）。',
        label: {
          audio_setting: '音频设置',
        }
      },
      video_converter: {
        name: '视频转换器',
        meta_description: '视频转换器可以使用您的浏览器免费转换各种视频文件。',
        detailed_description: '视频转换器可以使用您的浏览器免费转换各种视频（mp4、avi、mpg、mov、flv、3gp、webm、mkv）。',
        label: {
          video_setting: '视频设置',
          audio_setting: '音频设置',
        }
      },
      image_converter: {
        name: '图像转换器',
        meta_description: '图像转换器可以使用您的浏览器免费转换各种图像文件。',
        detailed_description: '图像转换器可以使用您的浏览器免费转换各种图像（png、jpeg、gif、webp）。',
        label: {
          effect_default: '返回默认值',
          effect_grayscale: '灰阶',
          effect_threshold: '二值化',
          effect_invert: '反转颜色',
          effect_gamma: '伽玛校正',
        }
      },
      regex_tester: {
        name: '正则表达式测试器',
        meta_description: '正则表达式测试器使测试正则表达式变得容易。',
        detailed_description: '正则表达式测试器使测试正则表达式变得容易。',
        label: {
          reference: '参考（MDN 网络文档）',
          flag: '标志',
          character_classes: '字符类',
          assertions: '断言',
          groups_and_ranges: '组和范围',
          quantifiers: '量词',
          result_unique: '删除重复并显示。',
          result_duplicate_only: '仅显示重复项。',
          result_duplicate_unique: '只有重复项被唯一化和显示。',
          result_all: '显示所有。',
          output: {
            match: '比赛',
            group: '组'
          }
        },
        url: {
          flag: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Guide/Regular_Expressions#advanced_searching_with_flags',
          character_classes: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types',
          assertions: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#types',
          groups_and_ranges: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#types',
          quantifiers: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Guide/Regular_Expressions/Quantifiers#types',
        },
      },
      //encode_tools: {
      //  name: '编码工具',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      yaml_tools: {
        name: 'YAML 工具',
        meta_description: 'YAML 工具可以对 YAML 数据进行格式化、压缩和转换为 JSON/TOML/XML 格式。',
        detailed_description: 'YAML 工具可以对 YAML 数据进行格式化、压缩和转换为 JSON/TOML/XML 格式。',
      },
      json_tools: {
        name: 'JSON 工具',
        meta_description: 'JSON 工具可以对 JSON 数据进行格式化、压缩和转换为 YAML/TOML/XML 格式。',
        detailed_description: 'JSON 工具可以对 JSON 数据进行格式化、压缩和转换为 YAML/TOML/XML 格式。',
      },
      toml_tools: {
        name: 'TOML 工具',
        meta_description: 'TOML 工具可以对 TOML 数据进行格式化、压缩和转换为 YAML/JSON/XML 格式。',
        detailed_description: 'TOML 工具可以对 TOML 数据进行格式化、压缩和转换为 YAML/JSON/XML 格式。',
      },
      xml_tools: {
        name: 'XML 工具',
        meta_description: 'XML 工具可以对 XML 数据进行格式化、压缩和转换为 YAML/JSON/TOML 格式。',
        detailed_description: 'XML 工具可以对 XML 数据进行格式化、压缩和转换为 YAML/JSON/TOML 格式。',
      },
      //csv_tools: {
      //  name: 'CSV 工具',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      uuid_generator: {
        name: 'UUID 生成器',
        meta_description: 'UUID 生成器可以根据 UUID/GUID 版本 4 共同生成唯一 ID。',
        detailed_description: 'UUID 生成器可以根据 UUID/GUID 版本 4 共同生成唯一 ID。',
      },
    },
  }
}
