window.getLangEs = function() {
  return {
    meta_description: 'Ezeful es una herramienta fácil, útil y gratuita.',
    detailed_description: '',
    validation: {
      audio: 'El :atributo debe ser un audio.',
      audio_or_video: 'El :atributo debe ser audio o video.',
      image: 'El :atributo debe ser una imagen.',
      video: 'El :atributo debe ser un video.',
      unknown: 'El procesamiento falló. Por favor, compruebe su entrada.',
      no_input: 'Fichero de entrada no especificado.',
    },
    label: {
      input: 'Aporte',
      output: 'Producción',
      target: 'Objetivo',
      result: 'Resultado',
      source_file: 'Archivo fuente',
      source_format: 'Formato de fuente',
      source_charset: 'Conjunto de caracteres de origen',
      source_encoding: 'Codificación de fuente',
      destination_format: 'Formato de destino',
      destination_charset: 'Conjunto de caracteres de destino',
      destination_encoding: 'Codificación de destino',
      execute: 'Ejecutar',
      download: 'Descargar',
      to_json: 'A JSON',
      to_yaml: 'A YAML',
      to_toml: 'A TOML',
      to_csv: 'A CSV',
      to_xml: 'A XML',
      delimiter: 'Delimitador',
      indent: 'Sangrar imprenta',
      space: 'espacio',
      transform: 'Transformar',
      convert: 'Convertir',
      beautify: 'Embellecer',
      minify: 'Minificar',
      generate: 'Generar',
      copy: 'Dupdo',
      open: 'Abrir',
      close: 'Cerrar',
      auto: 'Auto',
      number: 'Número',
      length: 'Longitud',
      regex: 'Expresión regular',
      sample: 'Ejemplo',
      option: 'Opción',
      flag: 'Bandera',
      quality: 'Calidad',
      preview: 'Vista previa',
      add_effect: 'Añadir efecto',
      previous: 'Anterior',
      next: 'Siguiente',
      encoder: 'Codificador',
      size: 'Tamaño',
      bit_rate: 'Tasa de bits',
      sampling_rate: 'Tasa de muestreo',
      frame_rate: 'Cuadros por segundo',
      not_specified: 'No especificado',
      not_change: 'Ningún cambio',
      auto_download: 'Descarga automática',
      advanced_setting: 'Configuración avanzada',
    },
    hint: {
      select: 'Por favor seleccione'
    },
    placeholder: {
      json: 'Por favor ingrese en formato JSON aquí.',
      yaml: 'Por favor ingrese en formato YAML aquí.',
      toml: 'Por favor ingrese en formato TOML aquí.',
      xml: 'Por favor ingrese en formato XML aquí.',
      csv: 'Por favor ingrese en formato CSV aquí.',
      regex: 'Por favor ingrese la expresión regular aquí.',
      text: 'Por favor ingrese el texto aquí.',
      string: 'Por favor ingrese la cadena aquí.',
    },
    alert: {
      result: 'Los resultados se muestran aquí.',
      copied: 'Fue copiado al portapapeles.',
    },
    attribute: {
      json: 'JSON',
      yaml: 'YAML',
      toml: 'TOML',
      xml: 'XML',
      csv: 'CSV',
      audio: 'Audio',
      video: 'Video',
      image: 'Imagen'
    },
    class: {
      multimedia: {
        name: 'Multimedia',
      },
      text: {
        name: 'Texto',
      },
      others: {
        name: 'Otros'
      },
      language: {
        name: 'Lenguaje'
      },
    },
    application: {
      contact: {
        name: 'Contacto'
      },
      privacy_policy: {
        name: 'Política de privacidad',
        meta_description: 'Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege. No usaremos ni compartiremos su información con nadie, excepto como se describe en esta Política de privacidad.',
        detailed_description: 'Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege. No usaremos ni compartiremos su información con nadie, excepto como se describe en esta Política de privacidad.',
        label: {
          about_analytics: {
            name:'Acerca de las herramientas de análisis de acceso',
            description: 'Este sitio utiliza Google Analytics, una herramienta de análisis de acceso de Google.<br><br>Google Analytics utiliza cookies para recopilar datos de tráfico. Estos datos de tráfico se han recopilado de forma anónima y no identifican a ninguna persona. <br><br>Las cookies de Google Analytics se conservan durante 26 meses. Esta función le permite rechazar la recopilación al deshabilitar las cookies, así que verifique la configuración de su navegador.<br><br>Si desea consultar los términos de uso de Google Analytics, visite <a href="https://www.google.com/analytics/terms/es.html" target="_blank">https://www.google.com/analytics/terms/es.html</a>.<br>Además, si desea conocer el "uso de datos de Google cuando los usuarios usan sitios y aplicaciones de socios de Google", vaya a <a href="https://policies.google.com/technologies/partner-sites?hl=es" target=" _blank">https://policies.google.com/technologies/partner-sites?hl=es</a>. Dame.'
          },
          about_advertisement: {
            name:'Acerca de los servicios de publicidad de terceros',
            //description: 'Este sitio utiliza el servicio de publicidad de terceros "Google Adsense".<br><br>Los distribuidores de publicidad de terceros, como Google, utilizan cookies (como información cuando los visitantes de este sitio acceden a este u otros sitios) para mostrar anuncios de acuerdo con a los intereses de los visitantes.<br><br>Los visitantes pueden deshabilitar los anuncios específicos de visitantes (en adelante, "anuncios personalizados") en la configuración de anuncios.<br>Para hacerlo, vaya a <a href="https://www.google.com/settings/ads" target="_blank">https://www.google.com/settings/ads</a> .<br>También puede inhabilitar las cookies de editores de terceros utilizadas para publicidad personalizada visitando <a href="https://www.aboutads.info" target="_blank">https://www.aboutads.info</a> .<br><br>Si no ha desactivado la ubicación de anuncios de terceros, los anuncios de distribuidores de terceros y las redes publicitarias pueden aparecer en su sitio.<br><br>Para obtener más información acerca de los distribuidores de anuncios a los que Google les permite publicar anuncios a terceros, visite <a href="https://support.google.com/dfp_premium/answer/94149" target="_blank">https://support.google.com/dfp_premium/answer/94149</a> .'
            description: 'Este sitio utiliza el servicio de publicidad de terceros.<br><br>Los distribuidores de publicidad de terceros utilizan cookies (como información cuando los visitantes de este sitio acceden a este u otros sitios) para mostrar anuncios de acuerdo con los intereses de los visitantes.<br><br>Puede eliminar o deshabilitar las cookies desde la configuración de su navegador.<br>Consulte la ayuda de su navegador para obtener más detalles.'
          },
          purpose_personal_information: {
            name:'Propósito del uso de la información personal',
            description: 'Es posible que este sitio solicite enviar información personal, como un nombre (nombre de usuario) y una dirección de correo electrónico cuando realice una consulta por correo electrónico.<br><br>Esta información personal se utilizará para responder preguntas y contactarlo por correo electrónico. correo, etc., y dicha información personal no se utilizará para ningún propósito más allá de dichos fines.'
          },
          disclosure_personal_information: {
            name:'Divulgación de información personal a terceros',
            description: 'En este sitio, la información personal es manejada adecuadamente, y no será revelada a tercera persona excepto en el caso que se indica a continuación.<br><br>1. Cuando exista consentimiento de la persona de que se trate<br>2. Cuándo se requiere la divulgación para cooperar con la ley'
          },
          correspond_personal_information: {
            name:'Divulgación, corrección, adición, eliminación y suspensión del uso de información personal',
            description: 'En el caso de la divulgación, corrección, adición, eliminación y suspensión del uso de datos personales de la persona en cuestión, confirmaremos que se trata de la persona en cuestión y nos comunicaremos de inmediato.'
          },
          children: {
            name:'Niños',
            description: 'El servicio de este sitio no está destinado a niños menores de 16 años, y no recopilamos deliberadamente información de niños menores de 16 años.<br><br>Los niños de 16 años o menos no deben enviar ninguna información personal sin el permiso de sus padres o tutores. Al usar el servicio de este sitio, declaras que tienes al menos 18 años o que tienes al menos 16 años y que tienes el permiso de tus padres o tutores para usar el servicio.'
          },
          eu_and_eea_users_rights: {
            name:'Derechos de los usuarios de la UE y el EEE',
            description: 'Si te encuentras habitualmente en la Unión Europea o Espacio Económico Europeo, tienes derecho a acceder, rectificar, descargar o borrar tu información, así como a limitar y oponerte a ciertos tratamientos de tu información. Si bien algunos de estos derechos se aplican en general, ciertos derechos se aplican solo en ciertas circunstancias limitadas. Describimos estos derechos a continuación:<br><br>Tiene derecho a acceder a sus datos personales y, si es necesario, modificarlos, eliminarlos o restringirlos.<br>En ciertos casos, puede tener derecho a la portabilidad de su datos.<br>También puede pedirnos que no enviemos comunicaciones de marketing y que no utilicemos sus datos personales cuando realicemos perfiles con fines de marketing directo.<br>Puede optar por no recibir boletines por correo electrónico y otras comunicaciones de marketing siguiendo las las instrucciones de exclusión que se le proporcionan en esos correos electrónicos.<br>Los mensajes de la cuenta transaccional no se verán afectados si opta por no recibir comunicaciones de marketing.'
          },
          california_privacy_rights: {
            name:'Derechos de los usuarios de California',
            description: 'Los residentes de California que brindan información personal para obtener productos o servicios para uso personal, familiar o doméstico tienen derecho a solicitar y obtener de nosotros, una vez por año calendario, información sobre la información del cliente que compartimos, si corresponde, con otras empresas para su propio uso directo. usos de marketing.<br>Si corresponde, esta información incluiría las categorías de información del cliente y los nombres y direcciones de aquellas empresas con las que compartimos información del cliente para el año calendario inmediatamente anterior.<br>Para obtener esta información, envíenos un correo electrónico en info@ezeful.app con el asunto "Solicitud de información de privacidad de California"; le enviaremos un correo electrónico de respuesta con la información solicitada.<br>No todo el intercambio de información está cubierto por los requisitos de "Shine the Light" de California y solo se incluirá información sobre el intercambio cubierto en nuestra respuesta.'
          },
          disclaimer: {
            name:'Descargo de responsabilidad',
            description: 'Por favor, comprenda que no somos responsables de ningún daño causado por el contenido publicado en este sitio.'
          },
          changes_privacy_policy: {
            name:'Cambios en la política de privacidad',
            description: 'Este sitio cumple con las leyes de los Estados Unidos y Japón aplicables a la información personal y se esfuerza por revisar y mejorar el contenido de esta política según corresponda.<br><br>La política de privacidad actualizada siempre se divulgará en esta página.'
          },
          last_updated: {
            name: 'Última actualización',
            description: '21 de enero de 2022',
          }
        }
      },
      audio_converter: {
        name: 'Convertidor de audio',
        meta_description: 'El convertidor de audio puede convertir varios archivos de audio y video en su navegador de forma gratuita.',
        detailed_description: 'El convertidor de audio puede convertir varios archivos de audio (mp3, wav, aac, ogg, m4a, m4r, flac, opus, aiff) y video (mp4, avi, mpg, mov, flv, 3gp, webm, mkv) en su navegador de forma gratuita.',
        label: {
          audio_setting: 'Configuracion de audio',
        }
      },
      video_converter: {
        name: 'Convertidor de video',
        meta_description: 'El convertidor de video puede convertir varios archivos de video de forma gratuita con su navegador.',
        detailed_description: 'El convertidor de video puede convertir varios archivos de video (mp4, avi, mpg, mov, flv, 3gp, webm, mkv) de forma gratuita con su navegador.',
        label: {
          video_setting: 'Configuración de vídeo',
          audio_setting: 'Configuracion de audio',
        }
      },
      image_converter: {
        name: 'Convertidor de imágenes',
        meta_description: 'El convertidor de imágenes puede convertir varios archivos de imágenes de forma gratuita con su navegador.',
        detailed_description: 'El convertidor de imágenes puede convertir varios archivos de imágenes (png, jpeg, gif, webp) de forma gratuita con su navegador.',
        label: {
          effect_default: 'Volver a valores predeterminados',
          effect_grayscale: 'Escala de grises',
          effect_threshold: 'Método del valor umbral',
          effect_invert: 'Invertir imagen',
          effect_gamma: 'Corrección gamma',
        }
      },
      regex_tester: {
        name: 'Probador de expresiones regulares',
        meta_description: 'Probador de expresiones regulares facilita la prueba de expresiones regulares.',
        detailed_description: 'Probador de expresiones regulares facilita la prueba de expresiones regulares.',
        label: {
          reference: 'Referencia (MDN Web Docs)',
          flag: 'Bandera',
          character_classes: 'Clases de caracteres',
          assertions: 'Aserciones',
          groups_and_ranges: 'Grupos y rangos',
          quantifiers: 'Cuantificadores',
          result_unique: 'Eliminar duplicados y mostrar.',
          result_duplicate_only: 'Mostrar solo duplicados.',
          result_duplicate_unique: 'Solo los duplicados son exclusivos y se muestran.',
          result_all: 'Mostrar todo.',
          output: {
            match: 'Coincidence',
            group: 'Grupos'
          }
        },
        url: {
          flag: 'https://developer.mozilla.org/es/docs/Web/JavaScript/Guide/Regular_Expressions#advanced_searching_with_flags',
          character_classes: 'https://developer.mozilla.org/es/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types',
          assertions: 'https://developer.mozilla.org/es/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#types',
          groups_and_ranges: 'https://developer.mozilla.org/es/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#types',
          quantifiers: 'https://developer.mozilla.org/es/docs/Web/JavaScript/Guide/Regular_Expressions/Quantifiers#types',
        },
      },
      //encode_tools: {
      //  name: 'Encode Tools',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      yaml_tools: {
        name: 'Herramientas YAML',
        meta_description: 'Las herramientas YAML pueden formatear, comprimir y convertir datos YAML a formato JSON/TOML/XML.',
        detailed_description: 'Las herramientas YAML pueden formatear, comprimir y convertir datos YAML a formato JSON/TOML/XML.',
      },
      json_tools: {
        name: 'Herramientas JSON',
        meta_description: 'Las herramientas JSON pueden formatear, comprimir y convertir datos JSON a formato YAML/TOML/XML.',
        detailed_description: 'Las herramientas JSON pueden formatear, comprimir y convertir datos JSON a formato YAML/TOML/XML.',
      },
      toml_tools: {
        name: 'Herramientas TOML',
        meta_description: 'Las herramientas TOML pueden formatear, comprimir y convertir datos TOML a formato YAML/JSON/XML.',
        detailed_description: 'Las herramientas TOML pueden formatear, comprimir y convertir datos TOML a formato YAML/JSON/XML.',
      },
      xml_tools: {
        name: 'Herramientas XML',
        meta_description: 'Las herramientas XML pueden formatear, comprimir y convertir datos XML a formato YAML/JSON/TOML.',
        detailed_description: 'Las herramientas XML pueden formatear, comprimir y convertir datos XML a formato YAML/JSON/TOML.',
      },
      //csv_tools: {
      //  name: 'Herramientas CSV',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      uuid_generator: {
        name: 'Generador de UUID',
        meta_description: 'El generador de UUID puede generar colectivamente ID únicos de acuerdo con la versión 4 de UUID/GUID.',
        detailed_description: 'El generador de UUID puede generar colectivamente ID únicos de acuerdo con la versión 4 de UUID/GUID.',
      },
    },
  }
}
