import Vue from 'vue'
import VueMeta from 'vue-meta'
//import VueI18n from 'vue-i18n'
import VueHead from 'vue-head'
import VueScript2 from 'vue-script2'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import $ from 'jquery'

window.$ = $
window.jQuery = $

require("bootstrap")

require('./config')

Vue.config.productionTip = false

Vue.use(VueMeta)
//Vue.use(VueI18n)
Vue.use(VueHead)
Vue.use(VueScript2)

/*
const messages = {
  en: {
    message: {
      welcome: 'Welcome to Your Vue.js App',
    }
  },
  ja: {
    message: {
      welcome: 'Vue.jsアプリケーションへようこそ',
    }
  }
}
*/
//const i18n = new VueI18n({
//  locale: 'ja',
//  messages,
//})

window.location.element = function(_element) {
  //$('html,body').animate({ scrollTop: $(_element).offset().top - 82 });
  var positionScroll = setInterval(function() {
      if (typeof $(_element).offset() !== 'undefined' && $(_element).css('display') != 'none') {
          clearInterval(positionScroll);
          $('html,body').animate({ scrollTop: $(_element).offset().top - 82 });
      }
  }, 0);
};

window.location.elementModal = function(_modal, _element) {
  var positionScroll = setInterval(function() {
      if (typeof $(_element).offset() !== 'undefined') {
          clearInterval(positionScroll);

          //要素の現在スクロールしている位置からの位置
          let movePos = $(_element).offset().top - $('html,body').scrollTop();
          //alert(movePos);
          //現在スクロールしている位置
          let currentPos = $(_modal).scrollTop();
          //alert(currentPos);
          //実際にスクロールする位置を計算
          let execPos = currentPos + movePos;
          //alert(execPos);
          $('.modal').scrollTop(execPos);
      }
  }, 0);
};

window.button = {
  displayTag: "<div class='button-loading'>%MESSAGE%</div>",
  displayMessage: 'Loading...',
  loading: function(event) {
    let obj = {};
    if (event.type == 'submit') {
      if ($(event.currentTarget).find('button[type=submit]:focus').length > 0) {
        obj = $(event.currentTarget).find('button[type=submit]:focus');
        obj.attr(
          'data-loading-text',
          this.displayTag.replace('%MESSAGE%', obj.html())
        );
        obj.button('loading');
      } else if ($(event.currentTarget).find('input[type=submit]:focus').length > 0) {
        obj = $(event.currentTarget).find('input[type=submit]:focus');
        obj.attr(
          'data-loading-text',
          this.displayTag.replace('%MESSAGE%', obj.html())
        );
        obj.button('loading');
      }
    } else if (event.type == 'click') {
      obj = $(event.currentTarget);
      obj.attr(
        'data-loading-text',
        this.displayTag.replace('%MESSAGE%', obj.html())
      );
      obj.button('loading');
    }
    return obj;
  },
  reset: function(obj) {
      if (typeof obj.button !== 'undefined') {
          obj.button('reset');
      }
  }
};

window.display = {
  displayTag: "<div class='display-loading'></div>",
  displayMessage: 'Loading...',
  isLoaded: function(obj) {
      if (typeof obj.loaded === 'undefined') {
          return false;
      } else if (typeof obj.loaded === undefined) {
          return false;
      } else if (obj.loaded === false) {
          return false;
      } else if (obj.loaded === true) {
          return true;
      }
      return false;
  },
  loading: function(obj) {
      obj.loaded = false;
  },
  reset: function(obj) {
      obj.loaded = true;
  }
};

window.preg_match_all = function(pattern, subject, flags) {

  let recs;
  let re;
  let lines;
  let parts;
  let i;
  let j;
  let lines_length;
  let parts_length;

  let option = ""
  let regexOption = new RegExp(/^\/.*\/([a-zA-z]+)$/)
  if (regexOption.test(pattern)) {
    let temp = regexOption.exec(pattern)[1].toLowerCase().split("")
    for (let i in temp) {
      if (temp[i] == "g" || temp[i] == "i" || temp[i] == "m" || temp[i] == "s" || temp[i] == "u" || temp[i] == "y") {
        option += temp[i];
      }
    }
  }

  //リテラル文字の除去
  let literal = pattern;
  if (regexOption.test(pattern)) {
    literal = literal.replace(/\/[a-zA-z]+$/, "/")
  }
  let regexLiteral = new RegExp(/^\/(.*)\/$/)
  if (regexLiteral.test(literal)) {
    literal  = literal.replace(/^\//, "")
    literal  = literal.replace(/\/$/, "")
  }
  recs = [];
  re = new RegExp(literal, option);
  lines = subject.match(re);

  re = new RegExp(literal, "");
  if (lines != null && lines != '' && typeof lines.length !== 'undefined') {
    lines_length = lines.length;

    for (i = 0; i < lines_length; i++) {
      parts = lines[i].match(re);
      parts_length = parts.length;
      if (flags === "PREG_PATTERN_ORDER" || flags === "") {
        for (j = 0; j < parts_length; j++) {
          if (typeof (recs[j]) === "undefined") {
            recs[j] = [];
          }
          if (typeof parts[j] !== 'undefined') {
            recs[j][i] = parts[j];
          }
        }
      } else if (flags === "PREG_SET_ORDER") {
        recs[i] = [];
        for (j = 0; j < parts_length; j++) {
          if (typeof parts[j] !== 'undefined') {
            recs[i][j] = parts[j];
          }
        }
      }
    }
  }

  return recs;

}

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
