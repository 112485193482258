window.getLangZhTw = function() { //繁體中文
  return {
    meta_description: 'Ezeful 是簡單、有用且免費的工具。',
    detailed_description: '',
    validation: {
      audio: ':attribute 必須是音頻。',
      audio_or_video: ':attribute 必須是音頻或視頻。',
      image: ':attribute 必須是圖像。',
      video: ':attribute 必須是視頻。',
      unknown: '處理失敗。 請檢查您的輸入。',
      no_input: '未指定輸入文件。',
    },
    label: {
      input: '輸入',
      output: '輸出',
      target: '目標',
      result: '結果',
      source_file: '源文件',
      source_format: '源格式',
      source_charset: '源字符集',
      source_encoding: '源編碼',
      destination_format: '目標格式',
      destination_charset: '目標字符集',
      destination_encoding: '目的地編碼',
      execute: '執行',
      download: '下載',
      to_json: '轉換為 JSON',
      to_yaml: '轉換為 YAML',
      to_toml: '轉換為 TOML',
      to_csv: '轉換為 CSV',
      to_xml: '轉換為 XML',
      delimiter: '分隔符',
      indent: '縮進',
      space: '空間',
      transform: '轉變',
      convert: '兌換',
      beautify: '美化',
      minify: '縮小',
      generate: '產生',
      copy: '複製',
      open: '打開',
      close: '關閉',
      auto: '自動的',
      number: '數字',
      length: '長度',
      regex: '正則表達式',
      sample: '樣本',
      option: '選項',
      flag: '標誌',
      quality: '質量',
      preview: '預覽',
      add_effect: '添加效果',
      previous: '前頁',
      next: '後頁',
      encoder: '編碼器',
      size: '大小',
      bit_rate: '比特率',
      sampling_rate: '採樣率',
      frame_rate: '幀率',
      not_specified: '未標明',
      not_change: '沒變化',
      auto_download: '自動下載',
      advanced_setting: '高級設置',
    },
    hint: {
      select: '請選擇。'
    },
    placeholder: {
      json: '請在此處輸入 JSON 格式。',
      yaml: '請在此處輸入 YAML 格式。',
      toml: '請在此處輸入 TOML 格式。',
      xml: '請在此處輸入 XML 格式。',
      csv: '請在此處輸入 CSV 格式。',
      regex: '請在此處輸入正則表達式。',
      text: '請在此處輸入文字。',
      string: '請在此處輸入字符串。',
    },
    alert: {
      result: '結果顯示在這裡。',
      copied: '它被複製到剪貼板。',
    },
    attribute: {
      json: 'JSON',
      yaml: 'YAML',
      toml: 'TOML',
      xml: 'XML',
      csv: 'CSV',
      audio: '音頻',
      video: '視頻',
      image: '圖像'
    },
    class: {
      multimedia: {
        name: '多媒體',
      },
      text: {
        name: '文字',
      },
      others: {
        name: '其他'
      },
      language: {
        name: '語'
      },
    },
    application: {
      contact: {
        name: '接觸'
      },
      privacy_policy: {
        name: '隱私政策',
        meta_description: '本隱私政策描述了我們在您使用服務時收集、使用和披露您的信息的政策和程序，並告訴您您的隱私權以及法律如何保護您。 除本隱私政策中所述外，我們不會使用或與任何人共享您的信息。',
        detailed_description: '本隱私政策描述了我們在您使用服務時收集、使用和披露您的信息的政策和程序，並告訴您您的隱私權以及法律如何保護您。 除本隱私政策中所述外，我們不會使用或與任何人共享您的信息。',
        label: {
          about_analytics: {
            name:'關於訪問分析工具',
            description: '本網站使用 Google Analytics，這是一種由 Google 提供的訪問分析工具。<br><br>Google Analytics 使用 cookie 來收集流量數據。 此流量數據是匿名收集的，不會識別任何個人。 <br><br>Google Analytics cookie 會保留 26 個月。 此功能允許您通過禁用 cookie 來拒絕收集，因此請檢查您的瀏覽器設置。<br><br>如果您想查看 Google Analytics 的使用條款，請訪問 <a href="https://www.google.com/analytics/terms/us.html" target="_blank">https://www.google.com/analytics/terms/us.html</a>。<br>另外，如果你想 了解“當用戶使用 Google 合作夥伴網站和應用程序時 Google 對數據的使用”，請轉到 <a href="https://policies.google.com/technologies/partner-sites?hl=zh-TW" target=" _blank">https://policies.google.com/technologies/partner-sites?hl=zh-TW</a>。 給我嗎。'
          },
          about_advertisement: {
            name:'關於第三方廣告服務',
            //description: '本網站使用第三方廣告服務“Google Adsense”。<br><br>谷歌等第三方廣告分銷商使用cookies（如本網站訪問者訪問本網站或其他網站時的信息）根據訪問者的利益。<br><br>訪問者可以在廣告設置中禁用特定於訪問者的廣告（以下簡稱“個性化廣告”）。<br>為此，請轉到 <a href="https://www.google.com/settings/ads" target="_blank">https://www.google.com/settings/ads</a> 。<br>您還可以禁用用於通過訪問 <a href="https://www.aboutads.info" target="_blank">https://www.aboutads.info</a> 進行個性化廣告。<br><br>如果您沒有禁用第三方廣告展示位置、來自第三方分銷商和廣告網絡的廣告可能會出現在您的網站上。<br><br>有關 Google 允許向第三方投放廣告的廣告分銷商的更多信息，請訪問<a href="https://support.google.com/dfp_premium/answer/94149" target="_blank">https://support.google.com/dfp_premium/answer/94149</a> 。'
            description: '本站使用第三方廣告服務。<br><br>第三方廣告分銷商使用cookies（如本站訪問者訪問本站或其他網站時的信息）根據訪問者的興趣展示廣告。<br><br>您可以從瀏覽器設置中刪除或禁用 cookie。請查看瀏覽器的幫助以獲取詳細信息。'
          },
          purpose_personal_information: {
            name:'個人信息的使用目的',
            description: '當我通過電子郵件查詢時，本網站可能會要求提交姓名（句柄名稱）和電子郵件地址等個人信息。<br><br>這些個人信息將用於回答問題並通過電子郵件與您聯繫 郵件等，並且此類個人信息不會用於超出此類目的的任何目的。'
          },
          disclosure_personal_information: {
            name:'向第三方披露個人信息',
            description: '在本網站，個人信息得到妥善管理，除以下情況外，不會向第三人透露。<br><br>1. 徵得當事人同意時<br>2. 當需要披露以配合法律時'
          },
          correspond_personal_information: {
            name:'個人信息的公開、更正、追加、刪除、停止使用',
            description: '對於當事人的個人數據的公開、更正、追加、刪除、停止使用等情況，我們將確認是當事人，並及時對應。'
          },
          children: {
            name:'孩子們',
            description: '本網站服務不適用於 16 歲以下的兒童，我們不會故意收集 16 歲以下兒童的信息。<br><br>16 歲或以下的兒童未經許可不得提交任何個人信息 他們的父母或監護人。 使用本網站服務，即表示您已年滿 18 周歲，或者您已年滿 16 周歲並獲得您的父母或監護人的許可使用該服務。'
          },
          eu_and_eea_users_rights: {
            name:'歐盟和歐洲經濟區用戶的權利',
            description: '如果您習慣性地位於歐盟或歐洲經濟區，您有權訪問、更正、下載或刪除您的信息，以及限制和反對對您的信息進行某些處理的權利。 雖然其中一些權利普遍適用，但某些權利僅適用於某些有限的情況。 我們在下面描述了這些權利：<br><br>您有權訪問您的個人數據，並在必要時對其進行修改、刪除或限制。<br>在某些情況下，您可能有權攜帶您的個人數據 數據。<br>您也可以要求我們在為直接營銷目的進行分析時不要發送營銷通訊，也不要使用您的個人數據。<br>您可以按照以下步驟選擇不接收電子郵件通訊和其他營銷通訊 這些電子郵件中提供給您的選擇退出說明。<br>如果您選擇退出營銷通信，交易帳戶消息將不受影響。'
          },
          california_privacy_rights: {
            name:'加州用戶權利',
            description: '在獲取產品或服務以供個人、家庭或家庭使用時提供個人信息的加州居民有權要求並從我們這裡獲取，每日曆年一次，關於我們與其他企業共享的客戶信息的信息（如果有的話），用於他們自己的直接 營銷用途。<br>如果適用，此信息將包括客戶信息的類別以及我們在前一個日曆年與之共享客戶信息的那些企業的名稱和地址。<br>要獲取此信息，請給我們發送電子郵件 info@ezeful.app，主題為“請求加州隱私信息”； 我們將向您發送一封包含所請求信息的回復電子郵件。<br>並非所有信息共享都包含在加利福尼亞“閃耀之光”要求中，我們的回復中僅包含有關共享共享的信息。'
          },
          disclaimer: {
            name:'免責聲明',
            description: '請理解，對於本網站上發布的內容造成的任何損害，我們概不負責。'
          },
          changes_privacy_policy: {
            name:'隱私政策的變更',
            description: '本網站遵守適用於個人信息的美國和日本法律，並努力酌情審查和改進本政策的內容。<br><br>更新後的隱私政策將始終在此頁面上披露。'
          },
          last_updated: {
            name: '最近更新時間',
            description: '二零二二年 一月 二十一號',
          }
        }
      },
      audio_converter: {
        name: '音頻轉換器',
        meta_description: '音頻轉換器可以在您的瀏覽器中免費轉換各種音頻和視頻文件。',
        detailed_description: '音頻轉換器可以在您的瀏覽器中免費轉換各種音頻（mp3、wav、aac、ogg、m4a、m4r、flac、opus、aiff）和視頻（mp4、avi、mpg、mov、flv、3gp、webm、mkv）。',
        label: {
          audio_setting: '音頻設置',
        }
      },
      video_converter: {
        name: '視頻轉換器',
        meta_description: '視頻轉換器可以使用您的瀏覽器免費轉換各種視頻文件。',
        detailed_description: '視頻轉換器可以使用您的瀏覽器免費轉換各種視頻（mp4、avi、mpg、mov、flv、3gp、webm、mkv）。',
        label: {
          video_setting: '視頻設置',
          audio_setting: '音頻設置',
        }
      },
      image_converter: {
        name: '圖像轉換器',
        meta_description: '圖像轉換器可以使用您的瀏覽器免費轉換各種圖像文件。',
        detailed_description: '圖像轉換器可以使用您的瀏覽器免費轉換各種圖像（png、jpeg、gif、webp）。',
        label: {
          effect_default: '返回默認值',
          effect_grayscale: '灰階',
          effect_threshold: '二值化',
          effect_invert: '反轉顏色',
          effect_gamma: '伽瑪校正',
        }
      },
      regex_tester: {
        name: '正則表達式測試器',
        meta_description: '正則表達式測試器使測試正則表達式變得容易。',
        detailed_description: '正則表達式測試器使測試正則表達式變得容易。',
        label: {
          reference: '參考（MDN 網絡文檔）',
          flag: '標誌',
          character_classes: '字符類',
          assertions: '斷言',
          groups_and_ranges: '組和範圍',
          quantifiers: '量詞',
          result_unique: '刪除重複並顯示。',
          result_duplicate_only: '僅顯示重複項。',
          result_duplicate_unique: '只有重複項被唯一化和顯示。',
          result_all: '顯示所有。',
          output: {
            match: '比賽',
            group: '組'
          }
        },
        url: {
          flag: 'https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions#advanced_searching_with_flags',
          character_classes: 'https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types',
          assertions: 'https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#types',
          groups_and_ranges: 'https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#types',
          quantifiers: 'https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions/Quantifiers#types',
        },
      },
      //encode_tools: {
      //  name: '編碼工具',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      yaml_tools: {
        name: 'YAML 工具',
        meta_description: 'YAML 工具可以對 YAML 數據進行格式化、壓縮和轉換為 JSON/TOML/XML 格式。',
        detailed_description: 'YAML 工具可以對 YAML 數據進行格式化、壓縮和轉換為 JSON/TOML/XML 格式。',
      },
      json_tools: {
        name: 'JSON 工具',
        meta_description: 'JSON 工具可以對 JSON 數據進行格式化、壓縮和轉換為 YAML/TOML/XML 格式。',
        detailed_description: 'JSON 工具可以對 JSON 數據進行格式化、壓縮和轉換為 YAML/TOML/XML 格式。',
      },
      toml_tools: {
        name: 'TOML 工具',
        meta_description: 'TOML 工具可以對 TOML 數據進行格式化、壓縮和轉換為 YAML/JSON/XML 格式。',
        detailed_description: 'TOML 工具可以對 TOML 數據進行格式化、壓縮和轉換為 YAML/JSON/XML 格式。',
      },
      xml_tools: {
        name: 'XML 工具',
        meta_description: 'XML 工具可以對 XML 數據進行格式化、壓縮和轉換為 YAML/JSON/TOML 格式。',
        detailed_description: 'XML 工具可以對 XML 數據進行格式化、壓縮和轉換為 YAML/JSON/TOML 格式。',
      },
      //csv_tools: {
      //  name: 'CSV 工具',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      uuid_generator: {
        name: 'UUID 生成器',
        meta_description: 'UUID 生成器可以根據 UUID/GUID 版本 4 共同生成唯一 ID。',
        detailed_description: 'UUID 生成器可以根據 UUID/GUID 版本 4 共同生成唯一 ID。',
      },
    },
  }
}
