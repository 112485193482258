import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n';

Vue.use(VueRouter)

require('../config')

let base = process.env.BASE_URL;
let localePath = String(window.location.pathname).match(/^\/(.*?)(\/|$)/)[1];
if (i18n.locale == localePath) {
  //base = `/${i18n.locale}/`
  base = `${i18n.locale}`
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: `/${base}/`,
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: `/${base}/privacypolicy`,
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  //{
  //  path: `/${base}/about`,
  //  name: 'About',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //},
  {
    path: `/${base}/audioconverter`,
    name: 'AudioConverter',
    component: () => import('../views/AudioConverter.vue')
  },
  {
    path: `/${base}/videoconverter`,
    name: 'VideoConverter',
    component: () => import('../views/VideoConverter.vue')
  },
  {
    path: `/${base}/imageconverter`,
    name: 'ImageConverter',
    component: () => import('../views/ImageConverter.vue')
  },
  {
    path: `/${base}/regextester`,
    name: 'RegexTester',
    component: () => import('../views/RegexTester.vue')
  },
  {
    path: `/${base}/uuidgenerator`,
    name: 'UuidGenerator',
    component: () => import('../views/UuidGenerator.vue')
  },
  //{
  //  path: `/${base}/encodetools`,
  //  name: 'EncodeTools',
  //  component: () => import('../views/EncodeTools.vue')
  //},
  //{
  //  path: `/${base}/csvtools`,
  //  name: 'CsvTools',
  //  component: () => import('../views/CsvTools.vue')
  //},
  {
    path: `/${base}/jsontools`,
    name: 'JsonTools',
    component: () => import('../views/JsonTools.vue')
  },
  {
    path: `/${base}/xmltools`,
    name: 'XmlTools',
    component: () => import('../views/XmlTools.vue')
  },
  {
    path: `/${base}/yamltools`,
    name: 'YamlTools',
    component: () => import('../views/YamlTools.vue')
  },
  {
    path: `/${base}/tomltools`,
    name: 'TomlTools',
    component: () => import('../views/TomlTools.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

window.application.base = base;

const router = new VueRouter({
  mode: 'history',
  //base: base,
  base: process.env.BASE_URL,
  routes
})

export default router
