window.getLangEn = function() {
  return {
    meta_description: 'Ezeful is easy, useful and free tools.',
    detailed_description: '',
    validation: {
      audio: 'The :attribute must be an audio.',
      audio_or_video: 'The :attribute must be audio or video.',
      image: 'The :attribute must be an image.',
      video: 'The :attribute must be a video.',
      unknown: 'Processing failed. Please check your input.',
      no_input: 'No input file specified.',
    },
    label: {
      input: 'Input',
      output: 'Output',
      target: 'Target',
      result: 'Result',
      source_file: 'Source File',
      source_format: 'Source Format',
      source_charset: 'Source Character Set',
      source_encoding: 'Source Encoding',
      destination_format: 'Destination Format',
      destination_charset: 'Destination Character Set',
      destination_encoding: 'Destination Encoding',
      execute: 'Execute',
      download: 'Download',
      to_json: 'To JSON',
      to_yaml: 'To YAML',
      to_toml: 'To TOML',
      to_csv: 'To CSV',
      to_xml: 'To XML',
      delimiter: 'Delimiter',
      indent: 'Indent',
      space: 'space',
      transform: 'Transform',
      convert: 'Convert',
      beautify: 'Beautify',
      minify: 'Minify',
      generate: 'Generate',
      copy: 'Copy',
      open: 'Open',
      close: 'Close',
      auto: 'Auto',
      number: 'Number',
      length: 'Length',
      regex: 'Regular Expression',
      sample: 'Sample',
      option: 'Option',
      flag: 'Flag',
      quality: 'Quality',
      preview: 'Preview',
      add_effect: 'Add effect',
      previous: 'Previous',
      next: 'Next',
      encoder: 'Encoder',
      size: 'Size',
      bit_rate: 'Bit rate',
      sampling_rate: 'Sampling Rate',
      frame_rate: 'Frame Rate',
      not_specified: 'Not specified',
      not_change: 'No change',
      auto_download: 'Automatic Download',
      advanced_setting: 'Advanced Setting',
    },
    hint: {
      select: 'Please select.'
    },
    placeholder: {
      json: 'Please enter in JSON format here.',
      yaml: 'Please enter in YAML format here.',
      toml: 'Please enter in TOML format here.',
      xml: 'Please enter in XML format here.',
      csv: 'Please enter in CSV format here.',
      regex: 'Please enter the regular expression here.',
      text: 'Please enter the text here.',
      string: 'Please enter the string here.',
    },
    alert: {
      result: 'The results are displayed here.',
      copied: 'It was copied to the clipboard.',
    },
    attribute: {
      json: 'JSON',
      yaml: 'YAML',
      toml: 'TOML',
      xml: 'XML',
      csv: 'CSV',
      audio: 'Audio',
      video: 'Video',
      image: 'Image'
    },
    class: {
      multimedia: {
        name: 'Multimedia',
      },
      text: {
        name: 'Text',
      },
      others: {
        name: 'Others'
      },
      language: {
        name: 'Language'
      },
    },
    application: {
      contact: {
        name: 'Contact'
      },
      privacy_policy: {
        name: 'Privacy Policy',
        meta_description: 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We will not use or share your information with anyone except as described in this Privacy Policy.',
        detailed_description: 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We will not use or share your information with anyone except as described in this Privacy Policy.',
        label: {
          about_analytics: {
            name:'About access analysis tools',
            description: 'This site uses Google Analytics, an access analysis tool by Google.<br><br>Google Analytics uses cookies to collect traffic data. This traffic data has been collected anonymously and does not identify any individual. <br><br>Google Analytics cookies are retained for 26 months. This feature allows you to reject the collection by disabling cookies, so check your browser settings.<br><br>If you would like to check the terms of use of Google Analytics, please visit <a href="https://www.google.com/analytics/terms/us.html" target="_blank">https://www.google.com/analytics/terms/us.html</a>.<br>Also, if you want to know about "Google\'s use of data when users use Google partner sites and apps," go to <a href="https://policies.google.com/technologies/partner-sites?hl=en" target="_blank">https://policies.google.com/technologies/partner-sites?hl=en</a>. give me.'
          },
          about_advertisement: {
            name:'About third-party advertising services',
            //description: 'This site uses the third-party advertising service "Google Adsense".<br><br>Third-party advertising distributors such as Google use cookies (such as information when visitors to this site access this site or other sites) to display advertisements according to the interests of visitors.<br><br>Visitors can disable visitor-specific advertisements (hereinafter referred to as "personalized advertisements") in the advertisement settings.<br>To do that, go to <a href="https://www.google.com/settings/ads" target="_blank">https://www.google.com/settings/ads</a> .<br>You can also disable third-party publisher cookies used for personalized advertising by visiting <a href="https://www.aboutads.info" target="_blank">https://www.aboutads.info</a> .<br><br>If you have not disabled third-party ad placement, ads from third-party distributors and ad networks may appear on your site.<br><br>For more information about ad distributors that are allowed to serve ads to third parties by Google, please visit <a href="https://support.google.com/dfp_premium/answer/94149" target="_blank">https://support.google.com/dfp_premium/answer/94149</a> .'
            description: 'This site uses the third-party advertising service.<br><br>Third-party advertising distributors use cookies (such as information when visitors to this site access this site or other sites) to display advertisements according to the interests of visitors.<br><br>You can remove or disable cookies from your browser settings.<br>Please check the help of your browser for details.'
          },
          purpose_personal_information: {
            name:'Purpose of use of personal information',
            description: 'This site might ask to submit personal information such as a name (handle name) and an email address when I inquire by e-mail.<br><br>These personal information will be used to answer questions and to contact you via e-mail, etc., and such personal information will not be used for any purpose beyond such purposes.'
          },
          disclosure_personal_information: {
            name:'Disclosure of personal information to third parties',
            description: 'In this site, the personal information is managed appropriately, and it will not be disclosed to the third person except the case which falls below.<br><br>1. When there is consent of the person in question<br>2. When disclosure is required to cooperate with the law'
          },
          correspond_personal_information: {
            name:'Disclosure, correction, addition, deletion and suspension of use of personal information',
            description: 'In the case of the disclosure, correction, addition, deletion, and the suspension of use of personal data from the person in question, we will confirm that it is the person in question, and we will correspond promptly.'
          },
          children: {
            name:'Children',
            description: 'This site service is not intended for children under the age of 16, and we do not knowingly collect information from children under the age of 16.<br><br>Children aged 16 or younger should not submit any personal information without the permission of their parents or guardians. By using this site service, you are representing that you are at least 18 years old, or that you are at least 16 years old and have your parents\' or guardians\' permission to use the service.'
          },
          eu_and_eea_users_rights: {
            name:'EU and EEA Users\' Rights',
            description: 'If you are habitually located in the European Union or European Economic Area, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances. We describe these rights below:<br><br>You have the right to access your personal data and, if necessary, have it amended or deleted or restricted.<br>In certain instances you may have the right to the portability of your data.<br>You can also ask us to not send marketing communications and not to use your personal data when we carry out profiling for direct marketing purposes.<br>You can opt out of receiving email newsletters and other marketing communications by following the opt-out instructions provided to you in those emails.<br>Transactional account messages will be unaffected if you opt-out from marketing communications.'
          },
          california_privacy_rights: {
            name:'California Users\' Rights',
            description: 'California residents who provide personal information in obtaining products or services for personal, family or household use are entitled to request and obtain from us, once per calendar year, information about the customer information we shared, if any, with other businesses for their own direct marketing uses.<br>If applicable, this information would include the categories of customer information and the names and addresses of those businesses with which we shared customer information for the immediately prior calendar year.<br>To obtain this information, please email us at info@ezeful.app with the subject line "Request for California Privacy Information"; we will send you a reply e-mail containing the requested information.<br>Not all information sharing is covered by the California "Shine the Light" requirements and only information on covered sharing will be included in our response.'
          },
          disclaimer: {
            name:'Disclaimer',
            description: 'Please understand that we are not responsible for any damage caused by the content posted on this site.'
          },
          changes_privacy_policy: {
            name:'Changes to the privacy policy',
            description: 'This site complies with the laws of the United States and Japan applicable to personal information and strives to review and improve the contents of this policy as appropriate.<br><br>The updated privacy policy will always be disclosed on this page.'
          },
          last_updated: {
            name: 'Last updated',
            description: 'January 21, 2022',
          }
        }
      },
      audio_converter: {
        name: 'Audio Converter',
        meta_description: 'Audio Converter can convert various audio and video files in your browser for free.',
        detailed_description: 'Audio Converter can convert various audio (mp3, wav, aac, ogg, m4a, m4r, flac, opus, aiff) and video (mp4, avi, mpg, mov, flv, 3gp, webm, mkv) files in your browser for free.',
        label: {
          audio_setting: 'Audio Setting',
        }
      },
      video_converter: {
        name: 'Video Converter',
        meta_description: 'Video Converter can convert various video files for free with your browser.',
        detailed_description: 'Video Converter can convert various video (mp4, avi, mpg, mov, flv, 3gp, webm, mkv) files for free with your browser.',
        label: {
          video_setting: 'Video Setting',
          audio_setting: 'Audio Setting',
        }
      },
      image_converter: {
        name: 'Image Converter',
        meta_description: 'Image Converter can convert various image files for free with your browser.',
        detailed_description: 'Image Converter can convert various image (png, jpeg, gif, webp) files for free with your browser.',
        label: {
          effect_default: 'Return to default',
          effect_grayscale: 'Gray Scale',
          effect_threshold: 'Thresholding',
          effect_invert: 'Invert',
          effect_gamma: 'Gamma correction',
        }
      },
      regex_tester: {
        name: 'Regex Tester',
        meta_description: 'Regex Tester makes it easy to test regular expressions.',
        detailed_description: 'Regex Tester makes it easy to test regular expressions.',
        label: {
          reference: 'Reference (MDN Web Docs)',
          flag: 'Flag',
          character_classes: 'Character classes',
          assertions: 'Assertions',
          groups_and_ranges: 'Groups and ranges',
          quantifiers: 'Quantifiers',
          result_unique: 'Delete duplicates and display.',
          result_duplicate_only: 'Show only duplicates.',
          result_duplicate_unique: 'Only duplicates are uniqueized and displayed.',
          result_all: 'Show all.',
          output: {
            match: 'Match',
            group: 'Group'
          }
        },
        url: {
          flag: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#advanced_searching_with_flags',
          character_classes: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types',
          assertions: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#types',
          groups_and_ranges: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#types',
          quantifiers: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Quantifiers#types',
        },
      },
      //encode_tools: {
      //  name: 'Encode Tools',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      yaml_tools: {
        name: 'YAML Tools',
        meta_description: 'YAML tools can format, compress, and convert YAML data to JSON / TOML / XML format.',
        detailed_description: 'YAML tools can format, compress, and convert YAML data to JSON / TOML / XML format.',
      },
      json_tools: {
        name: 'JSON Tools',
        meta_description: 'JSON tools can format, compress, and convert JSON data to YAML / TOML / XML format.',
        detailed_description: 'JSON tool can format, compress, and convert JSON data to YAML / TOML / XML format.',
      },
      toml_tools: {
        name: 'TOML Tools',
        meta_description: 'TOML tools can format, compress, and convert TOML data to YAML / JSON / XML format.',
        detailed_description: 'TOML tool can format, compress, and convert TOML data to YAML / JSON / XML format.',
      },
      xml_tools: {
        name: 'XML Tools',
        meta_description: 'XML tools can format, compress, and convert XML data to YAML / JSON / TOML format.',
        detailed_description: 'XML tools can format, compress, and convert XML data to YAML / JSON / TOML format.',
      },
      //csv_tools: {
      //  name: 'CSV Tools',
      //  meta_description: '',
      //  detailed_description: '',
      //},
      uuid_generator: {
        name: 'UUID Generator',
        meta_description: 'UUID generator can collectively generate unique IDs according to UUID / GUID version 4.',
        detailed_description: 'UUID generator can collectively generate unique IDs according to UUID / GUID version 4.',
      },
    },
  }
}
