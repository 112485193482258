<template>
  <div id="app_top">
    <router-view/>
  </div>
</template>

<script>
  //import $ from 'jquery';
  export default {
    watch: {
      '$route': function (to, from) {
        //let self = this
        if (to.path !== from.path) {
          //self.loading()
        }
      }
    },
    data () {
      return {
        locale: window.application.locale,
        title: window.application.title,
      }
    },
    mounted() {
      //console.log(this.$router);
      //let self = this
      //self.loading()
    },
    methods: {
      //loading() {
      //  $('#js-loader').show()
      //  $('#js-loader').delay(300).fadeOut(400)
      //},
    }
  }
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/
</style>
