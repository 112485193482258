import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

require('../config')

let fallbackLocale = 'en';
let locale = fallbackLocale;
let localePath = String(window.location.pathname).match(/^\/(.*?)(\/|$)/)[1];
if (
  //localePath == 'de' ||
  localePath == 'en' ||
  localePath == 'es' ||
  //localePath == 'fr' ||
  localePath == 'hi' ||
  //localePath == 'it' ||
  localePath == 'ja' ||
  //localePath == 'ko' ||
  //localePath == 'ru' ||
  localePath == 'zh_cn' ||
  localePath == 'zh_tw'
) {
  locale = localePath;
}

require('../locales/en.js');
require('../locales/es.js');
require('../locales/hi.js');
require('../locales/ja.js');
require('../locales/zh_cn.js');
require('../locales/zh_tw.js');
/*
/ja/aaaa/dddd/
/ja/aaaa/?aaa=bbb
/ja?aaa=bbb
*/

const messages = {
  en: {
    name: 'English',
    lang: window.getLangEn(),
  },
  es: {
    name: 'español',
    lang: window.getLangEs(),
  },
  hi: {
    name: 'हिन्दी',
    lang: window.getLangHi(),
  },
  ja: {
    name: '日本語',
    lang: window.getLangJa(),
  },
  zh_cn: {
    name: '简体中文',
    lang: window.getLangZhCn(),
  },
  zh_tw: {
    name: '繁體中文',
    lang: window.getLangZhTw(),
  },
}

window.application.locales = {};
for (let i in messages) {
  window.application.locales[i] = messages[i].name;
}

window.application.locale = locale;
window.application.lang = messages[locale].lang;
//console.log(window.application)

if (locale == 'zh_cn') {
  document.getElementsByTagName('html')[0].lang = 'zh-cn';
} else if (locale == 'zh_tw') {
  document.getElementsByTagName('html')[0].lang = 'zh-tw';
} else {
  document.getElementsByTagName('html')[0].lang = locale;
}

export default new VueI18n({
  silentTranslationWarn: true,
  locale: locale,
  fallbackLocale: fallbackLocale,
  messages: messages
  //messages: {
  //  ja: require('./locales/ja.json')
  //}
})