
window.application = {};
window.application.title = 'Ezeful';
window.application.description = 'Ezeful is easy and useful tools.';
window.application.domain = 'ezeful.app';
window.application.email = 'info@ezeful.app';
window.application.copy_right = '© 2021 ezeful.app';
window.application.adsense_script = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2704593577593121' 
window.application.converter = {};
window.application.converter.encoders = {};
window.application.converter.encoders.audio = {
  aac: {
    name: 'AAC',
    encoder: 'aac'
  },
  pcm_s16le: {
    name: 'WAV (PCM 16bit)',
    encoder: 'pcm_s16le'
  },
  libvorbis: {
    name: 'Vorbis',
    encoder: 'vorbis'
  },
  flac: {
    name: 'FLAC (Free Lossless Audio Codec)',
    encoder: 'flac'
  },
  mp2: {
    name: 'MP2',
    encoder: 'mp2'
  },
};

window.application.converter.encoders.video = {
  mpeg4: {
    name: 'MPEG4',
    encoder: 'mpeg4'//mpeg4 (FMP4 / 0x34504D46)
  },
  libvpx: {
    name: 'VP8',
    encoder: 'libvpx'
  },
  libvpx_vp9: {
    name: 'VP9',
    encoder: 'libvpx-vp9'
  },
  mpeg1video: {
    name: 'MPEG-1',
    encoder: 'mpeg1video'
  },
  mpeg2video: {
    name: 'MPEG-2',
    encoder: 'mpeg2video'
  },
  flv1: {
    name: 'FLV1',
    encoder: 'flv'
  },
  gif: {
    name: 'GIF',
    encoder: 'gif'
  },
};

window.application.converter.settings = {};
window.application.converter.settings.audio = {}
window.application.converter.settings.audio.sampling_rates = [
  '8000',
  //'11025',
  //'12000',
  '16000',
  '22050',
  '24000',
  '32000',
  '44100',
  '48000',
  '96000'
];

window.application.converter.settings.audio.bit_rates = [
  '32k',
  '48k',
  '64k',
  '80k',
  '96k',
  '128k',
  '192k',
  '256k',
  '320k',
  '384k',
  '512k',
];

window.application.converter.settings.video = {}
window.application.converter.settings.video.frame_rates = [
  '24',
  '25',
  '30',
  '48',
  '50',
  '60'
];

window.application.converter.settings.video.bit_rates = [
  '6.5M',
  '9.5M',
  '10M',
  '15M',
  '20M',
  '30M',
  '40M',
  '45M',
  '50M',
  '55M',
  '60M',
  '65M',
  '70M',
  '75M',
  '80M',
  '85M',
  '90M'
];

window.application.converter.settings.video.sizes = {
  sqcif: {
    name: 'SQCIF (Sub-QCIF)',
    description: '128×96',
    aspect: '4:3',
    value: '128x96',
  },
  qcif: {
    name: 'QCIF (Quarter-CIF)',
    description: '174×144',
    aspect: '11:9',
    value: '174x144',
  },
  ps144: {
    name: '144p',
    description: '256×144',
    aspect: '16:9',
    value: '256x144',
  },
  qvga: {
    name: 'QVGA (Quarter-VGA)',
    description: '320×240',
    aspect: '4:3',
    value: '320x240',
  },
  sif: {
    name: 'SIF (Mobile)',
    description: '352×240',
    aspect: '22:15',
    value: '352x240',
  },
  cif: {
    name: 'CIF (Mobile)',
    description: '352×288',
    aspect: '11:9',
    value: '352x288',
  },
  wqvga1: {
    name: 'WQVGA (Wide-QVGA)',
    description: '400×240',
    aspect: '15:9',
    value: '400x240',
  },
  ps240: {
    name: '240p',
    description: '426×240',
    aspect: '16:9',
    value: '426x240',
  },
  wqvga2: {
    name: 'WQVGA (Wide-QVGA)',
    description: '480×272',
    aspect: '16:9',
    value: '480x272',
  },
  hvga: {
    name: 'HVGA(Half-VGA)',
    description: '480×320',
    aspect: '3:2',
    value: '480x320',
  },
  ps360: {
    name: '360p',
    description: '640×360',
    aspect: '16:9',
    value: '640x360',
  },
  vga: {
    name: 'VGA (TV)',
    description: '640×480',
    aspect: '4:3',
    value: '640x480',
  },
  sdtv: {
    name: 'SDTV (DVD)',
    description: '720×480',
    aspect: '16:9/4:3',
    value: '720x480',
  },
  wvga: {
    name: 'WVGA (Wide-VGA)',
    description: '800×480',
    aspect: '15:9',
    value: '800x480',
  },
  fwvga: {
    name: 'FWVGA (Full-Wide-VGA) 480p',
    description: '854×480',
    aspect: '16:9',
    value: '854x480',
  },
  svga: {
    name: 'SVGA (Super-VGA)',
    description: '800×600',
    aspect: '4:3',
    value: '800x600',
  },
  wsvga: {
    name: 'WSVGA (Wide-SVGA)',
    description: '1024×600',
    aspect: '128:75(16:10)',
    value: '1024x600',
  },
  xga: {
    name: 'XGA',
    description: '1024×768',
    aspect: '4:3',
    value: '1024x768',
  },
  hdtv: {
    name: 'HDTV 720p',
    description: '1280×720',
    aspect: '16:9',
    value: '1280x720',
  },
  wxga1: {
    name: 'WXGA (Wide-XGA)',
    description: '1280×768',
    aspect: '15:9',
    value: '1280x768',
  },
  xga_plus: {
    name: 'XGA+',
    description: '1152×864',
    aspect: '4:3',
    value: '1152x864',
  },
  wxga2: {
    name: 'WXGA (Wide-XGA)',
    description: '1280×800',
    aspect: '16:10',
    value: '1280x800',
  },
  fwxga: {
    name: 'FWXGA (Full-Wide-XGA)',
    description: '1366×768',
    aspect: '16:9',
    value: '1366x768',
  },
  quad_vga: {
    name: 'Quad-VGA',
    description: '1280×960',
    aspect: '4:3',
    value: '1280x960',
  },
  wxga_plus: {
    name: 'WXGA+ (Wide-XGA+)',
    description: '1440×900',
    aspect: '16:10',
    value: '1440x900',
  },
  sxga: {
    name: 'SXGA (Super-XGA)',
    description: '1280×1024',
    aspect: '5:4',
    value: '1280x1024',
  },
  sxga_plus: {
    name: 'SXGA+',
    description: '1400×1050',
    aspect: '4:3',
    value: '1400x1050',
  },
  dttb: {
    name: 'ハイビジョン (地上デジタル)',
    description: '1440×1080',
    aspect: '4:3',
    value: '1440x1080',
  },
  wsxga: {
    name: 'WSXGA (Wide-SXGA)',
    description: '1600×1024',
    aspect: '25:16',
    value: '1600x1024',
  },
  wsxga_plus: {
    name: 'WSXGA+ (Wide-SXGA+)',
    description: '1680×1050',
    aspect: '16:10',
    value: '1680x1050',
  },
  uxga: {
    name: 'UXGA (Ultra-XGA)',
    description: '1600×1200',
    aspect: '4:3',
    value: '1600x1200',
  },
  fhd: {
    name: '2K/FHD(Full-HD) 1080p',
    description: '1920×1080',
    aspect: '16:9',
    value: '1920x1080',
  },
  wuxga: {
    name: 'WUXGA (Wide-UXGA)',
    description: '1920×1200',
    aspect: '16:10',
    value: '1920x1200',
  },
  qwxga: {
    name: 'QWXGA (Quad-Wide-XGA)',
    description: '2048×1152',
    aspect: '16:9',
    value: '2048x1152',
  },
  qxga: {
    name: 'QXGA (Quad-XGA)',
    description: '2048×1536',
    aspect: '4:3',
    value: '2048x1536',
  },
  wqhd: {
    name: 'WQHD (Wide-Quad-HD) 1440p',
    description: '2560×1440',
    aspect: '16:9',
    value: '2560x1440',
  },
  wqxga: {
    name: 'WQXGA (Wide-QXGA)',
    description: '2560×1600',
    aspect: '16:10',
    value: '2560x1600',
  },
  qwxga_plus: {
    name: 'QWXGA+ (Quad-Wide-XGA+)',
    description: '2880×1800',
    aspect: '16:10(8:5)',
    value: '2880x1800',
  },
  wqhd_plus: {
    name: 'WQHD+ (Wide-Quad-HD+)',
    description: '3200×1800',
    aspect: '16:9',
    value: '3200x1800',
  },
  quxga: {
    name: 'QUXGA (Quad-Ultra-XGA)',
    description: '3200×2400',
    aspect: '4:3',
    value: '3200x2400',
  },
  uhd: {
    name: '4K/UHD 2160p',
    description: '3840×2160',
    aspect: '16:9',
    value: '3840x2160',
  },
  uhd_cinema: {
    name: '4K Digital Cinema',
    description: '4096×2160',
    aspect: '256:135(17:9)',
    value: '4096x2160',
  },
  quxga_wide: {
    name: 'QUXGA Wide',
    description: '3840×2400',
    aspect: '16:10',
    value: '3840x2400',
  },
  shv: {
    name: '8K/SHV',
    description: '7680×4320',
    aspect: '16:9',
    value: '7680x4320',
  },
};

window.application.converter.outputs = {};
window.application.converter.outputs.audio = {
  wav: {
    name: 'wav',
    extension: 'wav',
    type: 'audio',
    default: {
      audio_encoder: 'pcm_s16le',
      audio_sampling_rate: '44100',
      audio_bit_rate: 'disabled'
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'pcm_s16le',
    ]
  },
  aac: {
    name: 'aac',
    extension: 'aac',
    type: 'audio',
    default: {
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'aac',
    ],
  },
  ogg: {
    name: 'ogg',
    extension: 'ogg',
    type: 'audio',
    default: {
      audio_encoder: 'libvorbis',
      audio_sampling_rate: '44100',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'libvorbis',
      'flac'
    ],
  },
  m4a: {
    name: 'm4a',
    extension: 'm4a',
    type: 'audio',
    default: {
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'aac',
    ],
  },
  m4r: {
    name: 'm4r',
    extension: 'm4r',
    type: 'audio',
    default: {
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'aac',
    ],
  },
  flac: {
    name: 'flac',
    extension: 'flac',
    type: 'audio',
    default: {
      audio_encoder: 'flac',
      audio_sampling_rate: '',
      audio_bit_rate: ''
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'flac'
    ],
  },
  aiff: {
    name: 'aiff',
    extension: 'aiff',
    type: 'audio',
    default: {
      audio_encoder: 'pcm_s16le',
      audio_sampling_rate: '',
      audio_bit_rate: ''
    },
    use_video_encoders: [],
    use_audio_encoders: [
      'pcm_s16le',
    ],
  },
};
window.application.converter.outputs.video = {
  mp4: {
    name: 'mp4',
    extension: 'mp4',
    type: 'video',
    default: {
      video_encoder: 'mpeg4',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'mpeg4',
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
      'mp2'
    ]
  },
  avi: {
    name: 'avi',
    extension: 'avi',
    type: 'video',
    default: {
      video_encoder: 'mpeg4',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [
      'mpeg4',
      'mpeg1video',
      'mpeg2video',
      'libvpx',
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
      'pcm_s16le'
    ]
  },
  mpg: {
    name: 'mpg',
    extension: 'mpg',
    type: 'video',
    default: {
      video_encoder: 'mpeg1video',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'mp2',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '192k'
    },
    use_video_encoders: [
      'mpeg1video',
      'mpeg2video'
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'mp2',
    ]
  },
  mov: {
    name: 'mov',
    extension: 'mov',
    type: 'video',
    default: {
      video_encoder: 'mpeg4',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'mpeg4',//
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
    ]
  },
  flv: {
    name: 'flv',
    extension: 'flv',
    type: 'video',
    default: {
      video_encoder: 'flv1',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'flv1',
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
      'pcm_s16le',
    ]
  },
  threegp: {
    name: '3gp',
    extension: '3gp',
    type: 'video',
    default: {
      video_encoder: 'mpeg4',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'aac',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'mpeg4',
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
    ]
  },
  webm: {
    name: 'webm',
    extension: 'webm',
    type: 'video',
    default: {
      video_encoder: 'libvpx_vp9',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'libvorbis',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'libvpx',
      'libvpx_vp9'
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'libvorbis',
    ]
  },
  mkv: {
    name: 'mkv',
    extension: 'mkv',
    type: 'video',
    default: {
      video_encoder: 'mpeg4',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: 'libvorbis',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'mpeg4',//
      'libvpx',
      'libvpx_vp9',
      'mpeg1video',
      'mpeg2video',
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: [
      'aac',
      'libvorbis',
      'pcm_s16le',
      'flac',
    ]
  },
  gif: {
    name: 'gif',
    extension: 'gif',
    type: 'video',
    default: {
      video_encoder: 'gif',
      video_frame_rate: '50',
      video_bit_rate: '9.5M',
      audio_encoder: '',
      audio_sampling_rate: 'disabled',
      audio_bit_rate: '384k'
    },
    use_video_encoders: [
      'gif'
    ],
    use_video_sizes: [
      'uhd',
      'fhd',
      'hdtv',
      'fwvga',
      'ps360',
      'ps240',
      'ps144',
      'sdtv',
      'vga',
      'qvga',
    ],
    use_audio_encoders: []
  },
  /*
  {
    name: 'android',
    encoder: [
      'libx264',
    ]
  },
  {
    name: 'apple',
    encoder: [
      'libx264',
    ]
  }
  */
};

window.application.converter.outputs.image = {
  png: {
    name: 'PNG',
    extension: 'png',
    use_quality: false,
    type: 'image',
    mime: 'image/png',
  },
  jpg: {
    name: 'JPEG',
    extension: 'jpg',
    use_quality: true,
    type: 'image',
    mime: 'image/jpeg',
  },
  gif: {
    name: 'GIF',
    extension: 'gif',
    use_quality: false,
    type: 'image',
    mime: 'image/gif',
  },
  webp: {
    name: 'WebP',
    extension: 'webp',
    use_quality: true,
    type: 'image',
    mime: 'image/webp',
  },
};

window.application.converter.settings.image = {};
window.application.converter.settings.image.effects = {
  grayscale: {
    name: '',
    description: '',
    value: 'grayscale',
  },
  threshold: {
    name: '',
    description: '',
    value: 'threshold',
  },
  invert: {
    name: '',
    description: '',
    value: 'invert',
  },
  gamma: {
    name: '',
    description: '',
    value: 'gamma',
  },
};

//for (let i in window.application.converter.outputs.audio) {
//  window.application.converter.outputs.video[i] = window.application.converter.outputs.audio[i];
//}
//console.log(window.application)